<template>
  <div>
    <table border="1" align="center" style="width: 100%; table-layout: fixed; overflow-wrap: break-word;">
      <thead>
        <tr>
          <th></th>
          <th>Tedavi</th>
          <th>Tanı</th>
          <th>Basamak</th>
          <th>Hazırlayan</th>
          <th>Başlama Tarihi</th>
          <th>Bitiş Tarihi</th>
          <th>Son Durum</th>
          <th>Tedavi Tipi</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(treatment, treatment_index) in p_treatmentList">
          <tr class="treatment-row">
            <td></td>
            <td @click="f_selectTreatment(treatment_index)"> {{ treatment.treatment_name }} </td>
            <td> {{ treatment.PatientDiagnosisName }} </td>
            <td> {{ treatment.step_no }} </td>
            <td> {{ treatment.CreatedByName }} </td>
            <td> {{ f_dateFormat(treatment.start_date) }} </td>
            <td> {{ f_dateFormat(treatment.end_date) }} </td>
            <td> {{ treatment.TreatmentStatusIdName }} </td>
            <td> {{ treatment.TreatmentTypeName }} </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from "moment";
export default {
  name: 'TreatmentListWidget',
  computed: mapGetters({
    // lang: 'lang'
  }),
  components: {},
  props: {
    p_treatmentList: {
      type: Array,
      required: true
    },
    p_treatmentData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {};
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_selectTreatment: function (tretment_index) {
      console.log("index : ", tretment_index);
      console.log("id : ", this.p_treatmentList[tretment_index].id);
      this.p_treatmentData.selected = this.p_treatmentList[tretment_index];
    },
    f_dateFormat: function (date) {
      let formatted_date = '';
      if (date) {
        formatted_date = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY");
      }
      return formatted_date;
    },
  },
  watch: {}
}

</script>

<style type="text/css">
.treatment-row {
  cursor: pointer;
}

.treatment-row:hover {
  background-color: #d0fff6;

}


</style>

